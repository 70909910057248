$(document).foundation();
$('#parish-select').select2();
$('#parish-select').on('select2:select', function (e) {
  var parishref = $("#parish-select").val();
  $("#decision-button").attr("href", parishref);
});
$('#giving-parish-select').select2();
$('#interest-select').select2();

$('#giving-parish-select').on('select2:select', function (e) {
 fundLink();
});
$('#interest-select').on('select2:select', function (e) {
  fundLink();
});

function fundLink(){
  var parishref = $("#giving-parish-select").val() || $("#giving-parish-select option").first().val();
  var giftData = $("#interest-select").val() || 'aodgivingday.org';
  console.log($("#interest-select").val());
  $("#decision-button").attr("href", parishref + '&giftData=' + giftData);
}